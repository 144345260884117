.Services{
    background-color: #fff;
    margin-left: 10%;
    margin-right: 10%;
}

.ServicesHead{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.ServicesGen{
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    text-align: justify;
    line-height: 2;
}

.OptionHead{
    font-family: Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-decoration: underline;
    text-align: left;
    margin-bottom: 5px;
}

.OptionBody{
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    text-align: justify;
    margin-bottom: 20px;
    line-height: 2;
}

@media only screen and (max-width: 450px) {
    .ServicesHead{
        font-size: 19px;
    }
    .ServicesGen, .OptionBody{
        font-size: 12px;
    }
    .OptionHead{
        font-size: 14px;
    }
}

@media only screen and (min-width: 451px) {
    .ServicesHead{
        font-size: 30px;
    }
    .ServicesGen, .OptionBody{
        font-size: 16px;
    }
    .OptionHead{
        font-size: 20px;
    }
}

@media only screen and (min-width: 1000px) {
    .ServicesHead{
        font-size: 40px;
    }
    .ServicesGen, .OptionBody{
        font-size: 20px;
    }
    .OptionHead{
        font-size: 24px;
    }
}