.GlobalError-container {
  position: relative;
  padding: 12px;
  background-color: rgb(59, 59, 59);
  border-width: 1px;
  border-color: #da5353;
  border-style: solid;
  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; */
  border-left-width: 10px;
}

.GlobalError-close {
  position: absolute;
  top: 12px;
  right: 12px;
}

.GlobalError-close .GlobalError-close-btn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-width: 0px;
  background-color: #da5353;
  color: #fff;
  font-size: 16px;
  border-radius: 2.5px;
  text-align: center;
  padding: 5px;
  /* padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px; */
  margin: 0px;
  line-height: 10px;
}

.GlobalError-message {
  text-align: left;
  color: rgb(199, 199, 199);
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 30px;
  font-size: 18px;
  font-weight: 600;
}
