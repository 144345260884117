.SpinLoader {
  margin: auto;
}

.SpinLoader .SpinLoader-img {
  display: flex;
}

.SpinLoader .SpinLoader-img img {
  width: 100%;
  height: 100%;
}
