.ContactUs{
    background-color: #fff;
}

.ContactColumn{
    display: flex;
    list-style: none;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px;
}

.li{
    display: inline;
}

.spacerCU{
    margin-left: 50px;
    margin-right: 50px;
}

.ContactHead{
    font-family: Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.ContactInfo, .ContactInfoMobile{
    font-family: Helvetica, sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 75px;
    max-width: 90%;
}
.ContactInfoMobile{
    display: none;
}
.ContactName,
.ContactAddress,
.ContactPhone,
.ContactEmail{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.ContactFormContainer{
    min-width: 50%;
    max-width: 100%;
}

.ContactInfoContainer{
    justify-self: flex-end;
    margin-left: auto;
    max-width: 90%;
    min-width: 35%;
}

.ContactForm{
    max-width: 90%;
}

.ContactImg{
   max-width: 100%;
   max-height: 700px;
}

.FieldLabel{
    text-align: left;
    margin-bottom: 5px;
    float: left;
}

.NameField,
.PhoneField,
.EmailField,
.MessageField{
    width: 100%;
    height: 50px;
    border: 2px;
    border-radius: 3px;
    border-color: #000;
    background-color: #f0f0f0;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    padding: 0px;
    padding-left: 5px;
    margin-bottom: 5px;
}

.MessageField{
    padding-top: 20px;
    height: 150px;
    resize: none;
}

.Required{
    font-size: 10pt;
    text-align: left;
    color: #000;
}

.Submit{
    width: 50%;
    height: 40px;
    border: 2px;
    border-radius: 3px;
    /* border-style: solid; */
    border-color: #000;
    background-color: #5BB982;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    color: #fff;
    font-size: 18pt;
    font-weight: 500;
    margin: 5px;
    margin-top: 10px;
}

.Submit:hover {
    background-color: #40835c;
    color: #fff;
}

@media only screen and (max-width: 450px) {
    .ContactInfo{
        display: none;
    }
    .ContactColumn, .spacerCU{
        margin-left: 20px;
        margin-right: 20px;
    }
    .ContactHead{
        font-size: 19px;
    }
    .FieldLabel, .Required, .Submit{
        font-size: 12px;
    }
    .ContactInfoMobile{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
        font-size: 14px;
    }
    .ContactImg{
        max-height: 300px;
    }
    .NameField, .PhoneField, .EmailField{
        height: 25px;
    }
    .MessageField{
        height: 75px;
    }
}

@media only screen and (min-width: 451px) {
    .ContactColumn, .spacerCU{
        margin-left: 35px;
        margin-right: 35px;
    }
    .ContactHead{
        font-size: 30px;
    }
    .FieldLabel, .Required, .Submit{
        font-size: 18px;
    }
    .ContactInfo{
        margin-right: auto;
        margin-top: 50px;
        font-size: 18px;
    }
    .ContactImg{
        max-height: 600px;
    }
    .NameField, .PhoneField, .EmailField{
        height: 30px;
    }
    .MessageField{
        height: 100px;
    }
}

@media only screen and (min-width: 1000px) {
    .ContactColumn, .spacerCU{
        margin-left: 50px;
        margin-right: 50px;
    }
    .ContactHead{
        font-size: 40px;
    }
    .FieldLabel, .Required, .Submit{
        font-size: 18px;
    }
    .ContactInfo{
        margin-right: auto;
        margin-top: 50px;
        font-size: 30px;
    }
    .ContactImg{
        max-height: 700px;
    }
    .NameField, .PhoneField, .EmailField{
        height: 50px;
    }
    .MessageField{
        height: 150px;
    }
}