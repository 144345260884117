.App {
  background-color: #3075a9;
}

.AppContainer{
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 5%;
  margin-right: 5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #3075a9;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-header {
  color: #000;
  background-color: #f0f0f0;
  min-height: 10vh;
  padding-top: 5px;
}

.home-header .navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-header .navbar-option{
  display: inline;
}

.home-body {
  color: #000;
  background-color: #fFF;
  min-height: 80vh;
  padding-top: 5px;
  padding-bottom: 25px;
}

.home-body-text {
  background-color: #f4f4f4;
}

.home-footer {
  color: #000;
  background-color: #f0f0f0;
  min-height: 10vh;
  padding-top: 10px;
  padding-left: 5%;
}

.copyright {
  text-align: left;
  color: #000;
  font-size: 11pt;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
}

.legal-note {
  text-align: left;
  color: #000;
  font-size: 9pt;
  font-family: Helvetica, sans-serif;
  margin-right: 30%;
  padding-left: 10px;
  padding-bottom: 10px;
}

.LogoImg{
  display: block;
  margin-left: 10px;
}

.navbar-container{
  width: 100%;
  background-color: #3075a9;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar{
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: 35px;
  width: 100%;
}

.navbar-option{
  text-align: center;
  color: #000;
  font-size: 18pt;
  font-family: Arial, Helvetica, sans-serif;
  border: #3075a9;
  border-style: solid;
  background-color: #3075a9;
}

.navbar-option-button{
  background-color: #5BB982;
  border-radius: 10px;
  color: #f0f0f0;
  font-size: 18pt;
}

.Navbutton{
  background-color: #5BB982;
  border-radius: 10px;
  color: #f0f0f0;
}
a{
  color: #fff;
  text-decoration: none;
}

.PageContainer{
  background-color: #fff;
  position:relative;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  z-index: 2;
}

.LogoAndLang{
  display: flex;
  margin: 5px;
}
.DropdownContent{
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 25px;
  margin-top: 10px;
}

.SelectedLanguage{
  display: flex;
  background: #5BB982;
  font-size: 14pt;
  font-weight: 500;
  color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
}

.DropdownContent:hover .LanguageContainer{
  display: block;
}

.LanguageContainer{
  display: none;
  margin-top: 2px;
  padding: 0;
  background:#5BB982;
  border-radius: 10px;
  box-shadow: 0PX 1PX 10PX #00000052;
}

.LanguageOption{
  list-style: none;
  text-align: center;
  padding: 5px;
  font-size: 12pt;
  font-weight: 500;
  color: #FFFFFF;
}

@media only screen and (max-width: 450px) {
  .LogoAndLang{
    margin-left: 0px;
    padding-left: 20px;
  }
  .LogoImg{
    margin-left: 0px;
    width: 60%;
    height: 60%;
  }
  .SelectedLanguage, .LanguageOption{
    font-size: 11pt;
  }
  .DropdownContent{
    margin-right: 10px;
  }
  .navbar{
    grid-template-rows: auto;
  }
  .navbar-container{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-option, .navbar-option-button{
    padding-top: 2px;
    font-size: 10pt;
  }
  .legal-note{
    margin-right: 10%;
  }
}

@media only screen and (min-width: 451px) {
  .LogoAndLang{
    margin-left: 0px;
    padding-left: 20px;
  }
  .LogoImg{
    margin-left: 0px;
    width: 60%;
    height: 60%;
  }
  .SelectedLanguage, .LanguageOption{
    font-size: 12pt;
  }
  .DropdownContent{
    margin-right: 15px;
  }
  .navbar{
    grid-template-rows: auto;
  }
  .navbar-container{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-option, .navbar-option-button{
    padding-top: 2px;
    font-size: 14pt;
  }
  .legal-note{
    margin-right: 15%;
  }
}

@media only screen and (min-width: 1000px) {
  .LogoAndLang{
    margin: 5px;
  }
  .LogoImg{
    margin-left: 10px;
    width: 50%;
    height: 50%;
  }
  .SelectedLanguage{
    font-size: 14pt;
  }
  .LanguageOption{
    font-size: 12pt;
  }
  .DropdownContent{
    margin-right: 15px;
  }
  .navbar{
    grid-template-rows: 35px;
  }
  .navbar-container{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-option, .navbar-option-button{
    font-size: 18pt;
  }
}