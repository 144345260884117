.Home{
    background-color: #fff;
}

.CoverPic{
    margin-left: 10px;
    margin-righT: 10px;
}

.Mission{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2;
}

.MissionLabel{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.homeLogo{
    text-align: center;
}

.MissionText{
    text-align: justify;
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2;
}

@media only screen and (max-width: 450px) {
    .MissionLabel{
        font-size: 19px;
    }
    .homeLogo{
        width: 20%;
    }
    .MissionText{
        font-size: 14px;
    }
}

@media only screen and (min-width: 451px) {
    .MissionLabel{
        font-size: 30px;
    }
    .homeLogo{
        width: 15%;
    }
    .MissionText{
        font-size: 18px;
    }
}

@media only screen and (min-width: 1000px) {
    .MissionLabel{
        font-size: 40px;
    }
    .homeLogo{
        width: 10%;
    }
    .MissionText{
        font-size: 24px;
    }
}