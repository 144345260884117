.AboutUs{
    background-color: #fff;

}
.ul{
    padding: 0px;
}

.AboutUsLabel{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.spacerAU{
    margin-right: 50px;
    margin-left: 50px;
}

.Column{
    margin-right: 50px;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    column-width: auto;
    list-style: none;
    padding: 0;
}

.PictureContainer{
    min-width: 350px;
    max-width: 750px;
    align-content: center;
    margin-right: 10px;
}

.Bio{
    max-width: 80%;
    margin-left: 10px;
    text-align: justify;
    font-size: 14pt;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2;
}

.AboutName{
    padding-top: 15px;
    text-align: center;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.singleSpace, .singleSpace1{
    line-height: 1;
}

.singleSpace1{
    padding-top: 10px;
}

@media only screen and (max-width: 450px) {
    .NonMobileContent{
        display: none;
    }
    .AboutUsLabel{
        font-size: 19px;
    }
    .Column{
        margin-left: 20px;
        margin-right: 20px;
        display: block;
    }
    .spacerAU{
        margin-left: 20px;
        margin-right: 20px;
    }
    .PictureContainer{
        min-width: 75px;
        max-width: 120px;
    }
    .AboutName{
        font-size: 14px;
    }
    .Bio{
        font-size: 9pt;
        width: 100%;
        display: grid;
        margin-right: 10px;
        grid-template-columns: 120px 75%;
        grid-template-rows: auto auto;
    }
    .grid2{
        grid-column: 1 / 3;
        grid-row: 2;
    }
}

@media only screen and (min-width: 451px) {
    .MobileContent{
        display: none;
    }
    .AboutUsLabel{
        font-size: 30px;
    }
    .Column{
        margin-left: 35px;
        margin-right: 35px;
    }
    .spacerAU{
        margin-left: 35px;
        margin-right: 35px;
    }
    .PictureContainer{
        min-width: 200px;
        max-width: 450px;
    }
    .AboutName{
        font-size: 18px;
    }
    .Bio{
        font-size: 10pt;
    }
}

@media only screen and (min-width: 1000px) {
    .MobileContent{
        display: none;
    }
    .AboutUsLabel{
        font-size: 40px;
    }
    .Column{
        margin-left: 50px;
        margin-right: 50px;
    }
    .spacerAU{
        margin-left: 50px;
        margin-right: 50px;
    }
    .PictureContainer{
        min-width: 350px;
        max-width: 700px;
    }
    .AboutName{
        font-size: 15px;
    }
    .Bio{
        font-size: 14pt;
    }
}