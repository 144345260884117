.Navigator {
  margin: auto;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .Navigator {
    max-width: 576px;
  }
}

.Navigator .Navigator-button-container {
  text-align: left;
  margin-bottom: 10px;
}

.Navigator .Navigator-back-button {
  background: #000;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
}

.Navigator .Navigator-screen-container.hide {
  display: none;
}
